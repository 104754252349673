<template>
  <div class="page">
    <div class="page-width content">
      <h2>FAQ</h2>
      <h4>What is the story behind the artwork for the Built With NFT Collection?</h4>
      <p>Having sought permission from the families of the children in BWB schools across Africa, the children were each given a template on which to paint a person in any way they wished. Once all of the paintings were completed, the artworks were scanned to create digital copies. These digital copies were supplied to the Satoshibles team for processing.</p>
      <p>Then using an algorithm to randomly combine a head and a body with a background, we are able to generate 10,000 completely unique artworks which are then stored as NFT’s. </p>

      <h4>What percentage of the proceeds will go to BWB?</h4>
      <p>100% of proceeds will go directly to the Built With Bitcoin Foundation. Secondary sales of Built-with-NFT NFTs when made on all marketplaces will generate a royalty payment to the Built with Bitcoin Foundation of 7.5%. From these royalty payments, 100% will also go to the ongoing fulfillment of the Built with NFT vision and mission.</p>

      <h4>Where will the funds go?</h4>
      <p>The first 13.41 ETH collected from this project was reinvested in the communities that made this collection possible. The Built With Bitcoin Foundation provided resources for art, music, culture, sports, and other extra curricular activities for BWB schools in Kenya, Rwanda, Nigeria, and El Salvador.</p>
      <p>All remaining proceeds will fund the Foundation’s on-going efforts to build communities that provide clean water, access to quality education, sustainable farming, and humanitarian support around the world with their next projects initiating in Africa, Asia, and Latin America.</p>
      <p>We will be adding new features to the website to keep you all up to date on the progress of the work, as well as build out special features for NFT holders.</p>

      <h4>How long will the NFT sale be available?</h4>
      <p>All NFTs are on sale today and will close once the last piece is sold.</p>

      <h4>Are the NFTs being minted on the Bitcoin Blockchain?</h4>
      <p>NFTs are minted on the Stacks Blockchain, all transactions on Stacks are backup and secured by the Bitcoin blockchain.</p>

      <h4>How do I buy an NFT?</h4>
      <p>
        You will need a crypto wallet to enable the purchase (mint) and receipt of your new NFTs. To mint your NFT on Bitcoin via Stacks, you will need to install the <a href="https://www.hiro.so/wallet" target="_blank">Hiro Wallet</a>. You can mint using STX token or directly with Bitcoin via Lightning. When minting via Bitcoin, a QR code for a lightning invoice will pop-up, and using any Lightning Wallet, the buyer will scan the QR code to send the funds.
      </p>
      <p>For Ethereum minting, we recommend MetaMask (<a href="https://metamask.io">https://metamask.io</a>) as the preferred choice of wallet - other Ethereum wallets may work but have not been tested. You can download and install MetaMask from the link above - be sure you download from the MetaMask site directly or from the Google Play or Apple App store. You can install on iPhone, Android or on desktop.</p>
      <p>
        Find out more here:
        <router-link to="/help">How to mint an NFT</router-link>
      </p>
    </div>
  </div>
</template>
