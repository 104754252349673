<template>
  <div class="page">
    <div class="page-width content">
      <h2>Help Section</h2>
      <h3>How do I buy an NFT?</h3>
      <p>You will need a crypto wallet to enable the purchase (mint) and receipt of your new NFTs. We recommend MetaMask (<a href="https://metamask.io">https://metamask.io</a>) as the preferred choice of wallet - other Ethereum wallets may work but have not been tested. You can download and install MetaMask from the link above - be sure you download from the MetaMask site directly or from the Google Play or Apple App store. You can install on iPhone, Android or on desktop.
      </p>
      <p>
        Built with NFTs will be priced in ETH (Ethereum) so you will need to add some ETH balance to your wallet once installed. There are a number of exchanges where you can purchase Ethereum using a credit card, some well known and safe to use exchanges are Binance, Coinbase, FTX.
        <a href="https://coinmarketcap.com/rankings/exchanges">https://coinmarketcap.com/rankings/exchanges</a>
      </p>
      <p>Typically you will receive the ETH into an exchange wallet - then you will need to send that ETH to your MetaMask wallet address. Each exchange will have a simple process to send ETH - follow the FAQ on your chasen exchange - make sure you copy your MetaMask wallet address and double check when you paste it into the send address on your exchange based wallet.</p>
      <p>Once your MetaMask wallet is active and is holding enough ETH (each NFT will be 0.06ETH +gas) then you are ready to go !</p>

      <h3>Gas - What is it and why should I care?</h3>
      <p>
        Gas is the transaction cost that you pay for taking any action on the Ethereum network. It is a variable cost and is not controlled by the NFT project. It can have a significant impact on the total transaction cost you will pay for your NFT as it is added on top of the NFT cost. You should always check the current gas price before submitting an ETH purchase transaction. You can view the current gas cost here:
        <a href="https://etherscan.io/gastracker">https://etherscan.io/gastracker</a>
      </p>
      <p>You can see specifically how much the gas is for your NFT mint by progressing through the mint process (outlined below) and before the final confirmation in your MetaMask wallet you will see a breakdown of the gas fees - in both ETH and fiat dollars. It will look something like this:</p>

      <p>When you want to mint, you will need to connect your MetaMask wallet to the
        <router-link to="/#mint">NFT minting page</router-link>
        by clicking on the “connect” button, this will load a small popup - select MetaMask and when prompted log in to your wallet. Once connected, simply set the number of NFTs you want to mint then press MINT. You will be prompted in your wallet to confirm the transaction - note that at this stage of the process you can review the proposed gas costs - if the gas is too high you can reject the transaction and wait for a lower gas price.
      </p>
      <p>Once the transaction is completed (depending on network congestion this may take anywhere from 10 seconds to several minutes) you will be able to see your NFTs ( it may take 5-10 minutes for them to show up ) either inside your MetaMask wallet app or you will be able to view them and the entire collection on OpenSea.</p>
      <p>OpenSea is a secondary marketplace for NFTs (<a href="https://opensea.io">OpenSea.io</a>). To find the collection, browse to OpenSea then in the search box simply type “Built with NFT” or
        <a href="https://opensea.io/collection/builtwithnft">click here</a></p>
      <p>For more details on how to use your MetaMask wallet see their FAQ and support info here: <a href="https://metamask.io/faqs.html">https://metamask.io/faqs.html</a></p>

      <h3>Need additional help?</h3>
      <p>For more specific help you can connect with our team on discord by clicking the below link, this will open a web browser version of Discord - then find the Built with NFT channel on the left hand side and ask in there with your request.</p>
      <p><a href="https://discord.gg/7Wm9Jg8MkW" class="button" style="margin-top: 20px; width: 100%; max-width: 300px;" target="_blank">Get Help in Discord</a></p>
    </div>
  </div>
</template>
